@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');

:root {
  --main-color: #1a316d;
  --track-color: #eeeeee;
}

.customSlider {
  width: 100%;
  margin: auto;
}

.customSlider-track {
  top: 8px;
  height: 4px;
  background: var(--track-color);
  border-radius: 2px;
}

.customSlider-track.customSlider-track-0 {
  background: var(--main-color);
}
